const availibleRoutes = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        groups: ['*'],
        _name: 'CSidebarNavItem',
        name: 'Заказы',
        to: '/orders',
        // icon: 'cil-energy'
        // icon: 'cil-object-group'
        icon: 'cil-calculator'

      },
      {
        groups: ['Администратор'],
        _name: 'CSidebarNavItem',
        name: 'Комплексы',
        to: '/equipment-items',
        icon: 'cil-settings'

      },
      // {
      //   groups:  ['Администратор'],
      //   _name: 'CSidebarNavItem',
      //   name: 'Типовые проекты',
      //   to: '/standard-projects',
      //   icon: 'cil-settings'

      // },
      {
        groups: ['Администратор'],
        _name: 'CSidebarNavItem',
        name: 'Детали',
        to: '/equipment-parts',
        icon: 'cil-chartPie'

      },
      {
        groups: ['Администратор'],
        _name: 'CSidebarNavItem',
        name: 'Запчасти',
        to: '/equipment-details',
        icon: 'cil-options'

      },
      // {
      //   groups:  ['Администратор'],
      //   _name: 'CSidebarNavItem',
      //   name: 'Справочники',
      //   to: '/dictionaries',
      //   icon: 'cil-file'

      // },
      {
        groups: ['Администратор'],
        _name: 'CSidebarNavItem',
        name: 'Пользователи',
        to: '/users',
        icon: 'cil-people'
      },
      {
        groups: ['*'],
        _name: 'CSidebarNavItem',
        name: 'Выход',
        to: '/logout',
        icon: 'cil-people'
      }
    ]
  }
]

export default availibleRoutes
