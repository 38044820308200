<template>
  <div class="c-app" v-if="user">
    <TheSidebar :user="user" />
    <CWrapper>
      <!-- <TheHeader/> -->
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>



<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import { mapActions } from "vuex";

export default {
  name: 'TheContainer',
  data() {
    return {
      user: null
    }
  },
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "users/getCurrentSuccess") {
        this.user = mutation.payload
      }
    });
    this["users/getCurrent"]();
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions(["users/getCurrent"]),
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
