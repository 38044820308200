<template>
  <CSidebar
    fixed
    :minimize="minimized"
    :show="visible"
    @update:show="
      (value) => $store.commit('ui/sidebarVisible', value)
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full icon-invert"
        :src="require(`../assets/images/kenguru-header-logo.svg`)"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        :src="require(`../assets/images/kenguru-header-logo-small.png`)"
        size="custom-size" 
        :height="20" 
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="toggleSidebarSize"
    />
  </CSidebar>
</template>

<style>
.icon-invert {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.c-sidebar {
  background-color: #000;
}

.c-sidebar-brand {
  margin-top: 20px;
  margin-left: 20px;
  justify-content: start;
}

.c-sidebar .c-sidebar-nav-link:hover {
  transition: 0.3s ease;
  text-decoration: none;
  background-color: #4d4d4d;
}

.c-sidebar .c-sidebar-nav-link.c-active {
  transition: 0.3s ease;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
}
</style>



<script>
import availibleRoutes from "./_nav";
import { mapState } from 'vuex';

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      nav: [
        {
          _name: "CSidebarNav",
          _children: [],
        },
      ],
    };
  },
  name: "TheSidebar",
  created() {
    for (const availibleRoute of availibleRoutes[0]._children) {
      if (this.currentUserCanAccessSection(availibleRoute.groups)) {
        this.nav[0]._children.push(availibleRoute);
      }
    }
  },
  computed: {
    ...mapState({
      visible: (state) => state.ui.sidebarVisible,
      minimized: (state) => state.ui.sidebarMinimized,
    }),
  },
  methods: {
    currentUserCanAccessSection(sectionGroups) {
      for (const sectionGroup of sectionGroups) {
        if (sectionGroup === "*") {
          return true;
        } else if (sectionGroup === this.user.group) {
          return true;
        }
      }
      return false;
    },
    toggleSidebarSize() {
      if (this.minimized) {
        this.$store.commit('ui/maximizeSidebar')
      } else {
        this.$store.commit('ui/minimizeSidebar')
      }
    }
  },
  
};
</script>
